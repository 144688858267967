import React, { useLayoutEffect } from 'react'
import Container from 'react-bootstrap/Container'
import { useScreenTitle } from '@whitecobalt/tungsten/esm/common/hooks/useScreenTitle'
import './index.scss'

interface NotFoundProps {
}

const NotFound: React.FunctionComponent<NotFoundProps> = ({}) => {

    useLayoutEffect(() => {
        const root = document.getElementById('root')

        root?.classList.add('no-half-bg')

        return () => root?.classList.remove('no-half-bg')
    }, [])

    useScreenTitle('404 - page not found')
    
    return (
        <Container className="d-flex justify-content-center">
            <div className="page-not-found">
                <h1>404</h1>
                <h3>Page not found</h3>
            </div>
        </Container>
    )
}

export default NotFound