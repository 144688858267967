import { PaymentDetailsPropType } from '@app/Layout'
import Button from '@whitecobalt/tungsten/esm/components/Button'
import PriceFormatField from '@whitecobalt/tungsten/esm/components/Fields/PriceFormatField'
import FormManager from '@whitecobalt/tungsten/esm/components/FormManager'
import { FormEvent } from 'formydable/esm'
import React, { useLayoutEffect } from 'react'
import Container from 'react-bootstrap/Container'
import Image from '@whitecobalt/tungsten/esm/components/Image'
import Loader from '@whitecobalt/tungsten/esm/components/Loader'
import Icon from '@whitecobalt/tungsten/esm/components/Icon'
import Card from 'react-bootstrap/Card'
import { useScreenTitle } from '@whitecobalt/tungsten/esm/common/hooks/useScreenTitle'
import worldTrigger from 'world-trigger'

interface ClientRequestFormProps {
    isClient: boolean;
    requesting: boolean;
    loading: boolean;
    success?: boolean;
    onSubmit: (event: FormEvent) => void;
    details: PaymentDetailsPropType | null;
}

const ClientRequestForm: React.FunctionComponent<ClientRequestFormProps> = ({isClient, requesting, loading, success, onSubmit, details}) => {

    useLayoutEffect(() => {
        const root = document.getElementById('root')

        root?.classList.add('no-half-bg')

        return () => root?.classList.remove('no-half-bg')
    }, [])

    useScreenTitle(loading ? 'Loading...' : `${details?.tradingName} - Payment Request`)
    
    return (
        <Container className="d-flex justify-content-center">
            {loading ? (
                <div className="py-5">
                    <Loader active className="bg-white"/>
                </div>
            ) : success ? (
                <div className="request-form">
                    <Card className="w-100">
                        <Card.Body className="text-center py-5">
                            <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                            </svg>
                            <h5 className="my-4" style={{color: '#555'}}>Payment Request Created!</h5>
                            <Button type="submit" className="mt-3" block onClick={() => worldTrigger.dispatchTrigger('reset.request')}>Request Another One!</Button>
                        </Card.Body>
                    </Card>
                </div>
            ) : (
                <div className="request-form">
                    <div className="pay-out">
                        <div className="text-center mb-4" data-env-impose="true">
                            <Image className="mt-3" src={(details?.companyLogoURL || '').replace('//images', '/images')} fallbackSrc="/media/logos/logo-default.png" alt={`${details?.tradingName} Logo`} />
                        </div>
                        <div className="pay-out-details">
                            {isClient && (
                                <div className="d-flex justify-content-between mb-3">
                                    <span className="">Client</span>
                                    <span className="primary-text">{details?.clientName}</span>
                                </div>
                            )}
                            <div className="d-flex justify-content-between">
                                <span className="">Company</span>
                                <span className="primary-text">{details?.tradingName}</span>
                            </div>
                        </div>
                        <div className="pay-out-total justify-content-start">
                            <FormManager className="w-100" onSubmit={onSubmit}>
                                {isClient ? (
                                    <FormManager.Input
                                        label="Matter Ref."
                                        name="matterReference"
                                        placeholder="e.g MTRREF10002"
                                    />
                                ) : (
                                    <>
                                        <FormManager.Input
                                            required
                                            label="First name"
                                            name="firstname"
                                            placeholder="e.g John"
                                        />
                                        <FormManager.Input
                                            required
                                            label="Surname"
                                            name="surname"
                                            placeholder="e.g Doe"
                                        />
                                        <FormManager.Input
                                            label="Reference"
                                            name="reference"
                                            placeholder="e.g REF10002"
                                        />
                                    </>
                                )}
                                
                                <FormManager.Input
                                    required
                                    as={PriceFormatField.Signed}
                                    label="Amount"
                                    name="amount"
                                    placeholder="100.00"
                                />
                                <FormManager.Input
                                    required={isClient === false}
                                    label="Receipt Email"
                                    name="receiptEmailAddress"
                                    placeholder="e.g example@email.com"
                                />
                                <Button type="submit" className="mt-3" block loading={requesting} disabled={requesting}>Make Payment <Icon className="ml-3" name="receipt"/></Button>
                            </FormManager>
                        </div>
                    </div>
                </div>
            )}
        </Container>
    )
}

export default ClientRequestForm