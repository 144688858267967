import Layout, { PaymentDetailsPropType } from '@app/Layout'
import { useAuthAPI } from '@whitecobalt/tungsten/esm/common/hooks/useAuthAPI'
import React, { useLayoutEffect, useMemo, useState } from 'react'
import worldTrigger from 'world-trigger'
import ReactDOM from 'react-dom'
import ClientRequestForm from './ClientRequestForm'
import { FormEvent } from 'formydable/esm'
import { darkenColor, isColorDark } from '@whitecobalt/tungsten/esm/common/utils/colors'
import FormManager from '@whitecobalt/tungsten/esm/components/FormManager'
import Toaster from '@whitecobalt/tungsten/esm/components/Toaster'
import branding from '@config/branding'
import { useWorldTrigger } from '@whitecobalt/tungsten/esm/common/hooks/useWorldTrigger'
import StripeProvider from '@components/StripeProvider';
import NotFound from '@app/Error/404'
import Loader from '@whitecobalt/tungsten/esm/components/Loader'
interface MainProps {

}

const Main: React.FunctionComponent<MainProps> = () => {
    const requestPaymentDetails = useAuthAPI<PaymentDetailsPropType>('/api/Payment')
    const requestPaymentClient = useAuthAPI('/api/Payment/request-client')
    const requestPaymentOpen = useAuthAPI('/api/Payment/request-open')
    const [successRequest, setSuccessRequest] = useState<boolean>(false)
    const [stripeAccount, setStripeAccount] = useState<any>(null)
    
    const keyCode = useMemo(() => {
        const qs = new URLSearchParams(window.location.search)

        return qs.get('Key') || qs.get('key')
    }, [])

    const clientKeyCode = useMemo(() => {
        const qs = new URLSearchParams(window.location.search)

        return qs.get('ClientKey') || qs.get('clientKey')
    }, [])

    const type = keyCode ? 'request' : clientKeyCode ? 'client' : 'open'

    useLayoutEffect(() => {
        const options: Record<string, any> = {
            url: "/api/Payment",
            data: {
                key: keyCode
            }
        }

        if(type === 'client') {
            options.url +=  "/client-details"
            options.data = {
                key: clientKeyCode
            }
        } else if (type === 'open') {
            options.url +=  "/open-details"
            options.data = {
                domain: window.location.hostname === 'localhost' ? 'whitecobalt.rendr-test.com' : window.location.hostname
            }
        }

        requestPaymentDetails.call(options)
        .then((response) => {
            if(type === 'request') {
                setStripeAccount(response.data?.stripeConnectAccountID)
            }
        })
    }, [])

    const handleRequest = (event: FormEvent) => {
        const FORM_DATA = event.json()

        const toast = Toaster("Requesting Payment", { placement: "top", duration: 5000 })

        if(type === "client") {

            const payload = {
                "organisationID": details?.organisationID || 0,
                "clientPaymentLink": clientKeyCode,
                "matterReference": FORM_DATA.matterReference,
                "amount": parseFloat(FORM_DATA.amount || "0"),
                "receiptEmailAddress": FORM_DATA.receiptEmailAddress
            }
            
            requestPaymentClient.call({
                data: payload
            })
            .then((response) => {
                if(response.data.success === true) {
                    setSuccessRequest(true)
                    setStripeAccount(response.data?.stripeConnectAccountID)
                    toast.success("Payment Created")
                } else {
                    toast.fail(response.data.message || branding.messages.fail)
                    FormManager.setBackendValidation(event, response.data?.errors)
                }
            })
            .catch((error) => {
                toast.error(error?.response.data?.message || branding.messages.error)
            })
        } else {
            const payload = {
                "organisationID": details?.organisationID || 0,
                "firstname": FORM_DATA.firstname,
                "surname": FORM_DATA.surname,
                "reference": FORM_DATA.reference,
                "amount": parseFloat(FORM_DATA.amount || "0"),
                "receiptEmailAddress": FORM_DATA.receiptEmailAddress
            }

            requestPaymentOpen.call({
                data: payload
            })
            .then((response) => {
                if(response.data.success === true) {
                    setSuccessRequest(true)
                    setStripeAccount(response.data?.stripeConnectAccountID)
                    requestPaymentOpen.setResponse({
                        ...response.data,
                        clientName: `${payload.firstname} ${payload.surname}`,
                        matterReference: payload.reference,
                        amount: payload.amount
                    })
                    toast.success("Payment Created")
                } else {
                    toast.fail(response.data.message || branding.messages.fail)
                    FormManager.setBackendValidation(event, response.data?.errors)
                }
            })
            .catch((error) => {
                toast.error(error?.response.data?.message || branding.messages.error)
            })
        }
        
    }

    useWorldTrigger('reset.request', () => {
        setSuccessRequest(false)
    }, [])

    const details = useMemo(() => {
        return type === 'request' 
        ? requestPaymentDetails.response 
        : {
            ...requestPaymentClient.response,
            ...requestPaymentOpen.response,
            ...requestPaymentDetails.response
        } as PaymentDetailsPropType
    }, [requestPaymentDetails.response, requestPaymentClient.response, requestPaymentOpen.response])

    const backgroundColour = useMemo(() => details?.colours?.find((each: any) => each.colourID === 1)?.hexCode || '#fff', [details])
    const textColour = useMemo(() => details?.colours?.find((each: any) => each.colourID === 2)?.hexCode || '#000', [details])
    const highlightColour = useMemo(() => details?.colours?.find((each: any) => each.colourID === 3)?.hexCode || '#000', [details])
    const highlightBtnTextColour = useMemo(() => details?.colours?.find((each: any) => each.colourID === 4)?.hexCode || '#000', [details])

    if(requestPaymentDetails.loading) {
        return (
            <Loader active />
        )
    }

    return (
        <>
            <style>
                {`
                    body {
                        background-color: ${backgroundColour};
                        color: ${textColour};
                    }

                    .pay-out .pay-out-total {
                        border-color: ${backgroundColour} !important;
                    }

                    .pay-out .pay-out-total::before, .pay-out .pay-out-total::after {
                        background-color: ${backgroundColour} !important;
                    }

                    .introduction .text-footer {
                        color: ${isColorDark(backgroundColour!) ? '#fff' : textColour}
                    }
                    
                    .common-text {
                        color: ${textColour};
                    }

                    .primary-text {
                        color: ${darkenColor(highlightColour, isColorDark(highlightColour!) ? 0 : 0.3)};
                    }

                    .btn.btn-primary {
                        background-color: ${highlightColour};
                        color: ${highlightBtnTextColour};
                    }

                    .btn.btn-primary:hover {
                        background-color: ${darkenColor(highlightColour, 0.4)};
                        color: ${highlightBtnTextColour};
                    }
                `}
            </style>
            {details?.favIconURL && ReactDOM.createPortal(
                <link rel="icon" href={details?.favIconURL.replace('//images', '/images')} />
            , document.head)}
            {(window.location.pathname === '/pay' && window.location.host !== 'rendr.co.uk') ? 
                (type === "request" || successRequest === true ? (
                    <StripeProvider key={stripeAccount} stripeAccountRequired={!!stripeAccount} stripeAccount={stripeAccount}>
                        <Layout 
                            keyCode={keyCode || details?.urlToken} 
                            loading={requestPaymentDetails.loading} 
                            details={details}/>
                    </StripeProvider>
                ) : (
                    <ClientRequestForm 
                        isClient={type === 'client'} 
                        onSubmit={handleRequest} 
                        details={details} 
                        loading={requestPaymentDetails.loading} 
                        requesting={requestPaymentOpen.loading || requestPaymentClient.loading} />
                )
            ) : (
                <NotFound />
            )}
        </>
    )
}

export default Main