import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useLinearToken } from "@whitecobalt/tungsten/hooks/useLinearToken";
import { useReceiveUser } from '@whitecobalt/tungsten/hooks/useReceiveUser';
import { useUpdateApplication } from '@whitecobalt/tungsten/hooks/useUpdateApplication';
import ErrorBoundary from "@whitecobalt/tungsten/ErrorBoundary";
import Main from './Main';

const Application = () => {
	const location = useLocation()

	useLinearToken()
	useReceiveUser()
	useUpdateApplication(5)

	useEffect(() => {
		if(document?.documentElement) {
			document.documentElement.scrollTop = 0
		}
	}, [location.pathname])

	return (
		<ErrorBoundary>
			<Main />
		</ErrorBoundary>
	);
}

export default Application;