import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react'
import Alert from 'react-bootstrap/Alert'
import Card from 'react-bootstrap/Card'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import FormManager from '@whitecobalt/tungsten/FormManager'
import { useAuthAPI } from '@whitecobalt/tungsten/hooks/useAuthAPI'
import { useScreenTitle } from '@whitecobalt/tungsten/hooks/useScreenTitle'
import { FormEvent } from 'formydable'
import Button from '@whitecobalt/tungsten/esm/components/Button'
import Loader from '@whitecobalt/tungsten/esm/components/Loader'
import Icon from '@whitecobalt/tungsten/esm/components/Icon'
import Image from '@whitecobalt/tungsten/esm/components/Image'
import CardElementForm from '@components/StripeProvider/CardElementForm'
import { CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js'
import PayButton from '@components/StripeProvider/PayButton'
import './index.scss'
import { StripeCardNumberElementChangeEvent } from '@stripe/stripe-js'

type StateProps = {
    error: string | null;
    success: string | null;
    readyPayButton: boolean;
    showDivider: boolean;
    submitting: boolean;
    cardType?: "visa" | "mastercard" | "amex" | "discover" | "diners" | "jcb" | "unionpay" | "unknown";
}

export type PaymentDetailsPropType = {
    amount: number;
    clientName: string | null;
    clientSecret: string | null;
    urlToken: string | null;
    colours: Array<{colourID: number; hexCode: string}>
    companyLogoURL: string | null;
    favIconURL: string | null;
    intentID: string | null;
    matterReference: string | null;
    message: string;
    success: boolean;
    tradingName: string | null;
    description: string | null;
    paymentComplete: boolean;
    cancelled: boolean;
    stripeConnectAccountID: string;
    organisationID: number;
    allowAMEX: boolean;
}

interface LayoutProps {
    keyCode?: string | null;
    details: PaymentDetailsPropType | null;
    loading: boolean;
}

const Layout: React.FunctionComponent<LayoutProps> = ({details, keyCode, loading}) => {
    const stripe = useStripe()
    const elements = useElements()
    const [{success, error, readyPayButton, showDivider, submitting, cardType}, setStates] = useState<StateProps>({
        error: null,
        success: null,
        readyPayButton: true,
        showDivider: false,
        submitting: false,
        cardType: 'unknown'
    })
    
    const request = useAuthAPI('/api/Payment/complete')

    const amount = (details?.amount || 0).toLocaleString('en-UK', { style: 'currency', currency: 'GBP'})
    const amountSplitted = amount.split('.')


    const handleSubmit = async (event: FormEvent) => {
        const payload = {
            "intentID": details?.intentID,
            "key": keyCode
        }

        if(details?.allowAMEX === false && cardType === 'amex') {
            return setStates(prev => ({...prev, error: "American Express is not allowed as a payment. Please enter another card."}));
        }
        
        try {
            setStates(prev => ({...prev, submitting: true}))
            const response = await stripe?.confirmCardPayment(details?.clientSecret!, {
                payment_method: {
                    card: elements?.getElement(CardNumberElement)!
                },
                return_url: `${window.location.href}`
            })

            if (response?.error) {
                setStates(prev => ({...prev, error: response?.error?.message || null, submitting: false}))
            } else {
                request.call({
                    data: payload
                })
                .then((response) => {
                    if(response.data.success) {
                        setStates(prev => ({...prev, success: response.data.message, submitting: false}))
                    } else {
                        setStates(prev => ({...prev, error: response.data.message, submitting: false}))
                    }
                })
                .catch((error) => {
                    setStates(prev => ({...prev, submitting: false}))
                })
            }
        } catch(error) {
            console.error(error)
            setStates(prev => ({...prev, error: error?.message || error, submitting: false}))
        }
    }

    const handlePaymentOnButton = useCallback(async (ev: any) => {
        // Confirm the PaymentIntent without handling potential next actions (yet).
        try {
            setStates(prev => ({...prev, submitting: true}))
            const response = await stripe?.confirmCardPayment(details?.clientSecret!, 
                {payment_method: ev.paymentMethod.id},
                {handleActions: false}
            );
            
            if (response?.error) {
                // Report to the browser that the payment failed, prompting it to
                // re-show the payment interface, or show an error message and close
                // the payment interface.
                setStates(prev => ({...prev, error: response?.error?.message || null, submitting: false}))
                ev.complete('fail');
            } else {
                // Report to the browser that the confirmation was successful, prompting
                // it to close the browser payment method collection interface.
                ev.complete('success');
                // Check if the PaymentIntent requires any actions and if so let Stripe.js
                // handle the flow. If using an API version older than "2019-02-11"
                // instead check for: `paymentIntent.status === "requires_source_action"`.

                
                const successFunc = (intentID: string) => {

                    const payload = {
                        "intentID": intentID,
                        "key": keyCode
                    }

                    request.call({
                        data: payload
                    })
                    .then((response) => {
                        if(response.data.success) {
                            setStates(prev => ({...prev, success: response.data.message, submitting: false}))
                        } else {
                            setStates(prev => ({...prev, error: response.data.message, submitting: false}))
                        }
                    })
                    .catch((error) => {
                        setStates(prev => ({...prev, error: error.message, submitting: false}))
                    })
                }

                if (response?.paymentIntent.status === "requires_action") {
                    // Let Stripe.js handle the rest of the payment flow.
                    const res = await stripe?.confirmCardPayment(details?.clientSecret!);
    
                    if (res?.error) {
                    // The payment failed -- ask your customer for a new payment method.
                        setStates(prev => ({...prev, error: res?.error?.message || null, submitting: false}))
                    } else {
                    // The payment has succeeded.
                        successFunc(res?.paymentIntent.id! || details?.intentID!)
                    }
                } else {
                    successFunc(response?.paymentIntent!.id! || details?.intentID!)
                    // The payment has succeeded.
                }
            }

        } catch(error) {
            console.error(error)
            setStates(prev => ({...prev, error: error?.message || error, submitting: false}))
        } 
    }, [stripe, details?.intentID, details?.clientSecret, keyCode])

    const handleSupportPayButton = useCallback((s: boolean) => setStates(prev => ({...prev, showDivider: s})), [])

    const handleReady = useCallback(() => setStates(prev => ({...prev, readyPayButton: false})), [])

    const handleCardChange = useCallback((event: StripeCardNumberElementChangeEvent) => {
        setStates(prev => ({...prev, cardType: event.brand}))
    }, [])

    useLayoutEffect(() => {
        const meta = document.querySelector('meta[name="description"]')
        if(!details?.success) {
            meta?.setAttribute('content',  '404 page not found')
        } else {
            meta?.setAttribute('content',  `${details.clientName} payment(${amount}) for ${details.tradingName} against Matter ${details.matterReference}`)
        }
    }, [details])

    useScreenTitle(loading ? 'Loading...' : `${details?.clientName} - payment ${amount}` || '404 page not found')

    const pathname = window.location.pathname


    return (
        <Container className="response-form-layout">
            {loading ? (
                <div className="py-5">
                    <Loader active className="bg-white"/>
                </div>
            ) : pathname === '/pay' && (details?.success === true || details?.paymentComplete === true) ? (
                <div className="h-100">
                    <Row className="h-100">
                        <Col sm={6} className="introduction">
                            <div className="section-header px-lg-5">
                                {/* <Info title="Client">{details?.clientName || "John Doe"}</Info>
                                <Info title="Company">{details?.tradingName || "White Cobalt LTD."}</Info>
                                <Info title="Matter"><Badge variant="info">{details?.matterReference || "DLSAAS2312"}</Badge></Info> */}
                                <div className="pay-out">
                                    <div className="text-center mb-4" data-env-impose="true">
                                        <Image className="mt-3" src={(details?.companyLogoURL || '').replace('//images', '/images')} fallbackSrc="/media/logos/logo-default.png" alt={`${details?.tradingName} Logo`} />
                                    </div>
                                    <div className="pay-out-details">
                                        <div className="d-flex justify-content-between mb-3">
                                            <span className="">Client</span>
                                            <span className="primary-text">{details?.clientName}</span>
                                        </div>
                                        <div className="d-flex justify-content-between mb-3">
                                            <span className="">Company</span>
                                            <span className="primary-text">{details?.tradingName}</span>
                                        </div>
                                        <div className="d-flex justify-content-between mb-3">
                                            <span className="">Matter</span>
                                            <span className="primary-text">{details?.matterReference}</span>
                                        </div>
                                        {details?.description && (
                                            <div className="mb-3">
                                                <span className="pay-out-description primary-text">{details?.description}</span>
                                            </div>
                                        )}
                                    </div>
                                    <div className="pay-out-total">
                                        <div>
                                            <p className="mb-0">Please Pay</p>
                                            <h1 className="primary-text mb-0">{amountSplitted[0]}<span style={{fontSize: 20}}>.{amountSplitted[1]}</span></h1>
                                        </div>
                                        <div className="pay-out-total-icon">
                                            <Icon name="receipt"/>
                                        </div>
                                    </div>
                                </div>
                                <br/>
                            </div>
                            <div className="section-footer px-lg-5 mt-auto d-none d-sm-block">
                                <p className="text-footer">Powered by <b>Rendr</b></p>
                            </div>
                        </Col>
                        <Col className="col-form" sm={6}>
                            <div className="section-form px-lg-5 h-100">
                                {details?.cancelled === true ? (
                                    <div className="d-flex align-items-center">
                                        <Card className="w-100">
                                            <Card.Body className="text-center py-5">
                                                <svg
                                                    fill="#af1818"
                                                    viewBox="0 0 36 36"
                                                    width="120px"
                                                    height="120px"
                                                    version="1.1"
                                                    preserveAspectRatio="xMidYMid meet"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                                    transform="rotate(0)matrix(1, 0, 0, 1, 0, 0)"
                                                    stroke="#af1818"
                                                    strokeWidth="0.00036"
                                                    >
                                                    <g strokeWidth={0} />
                                                    <g
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        stroke="#CCCCCC"
                                                        strokeWidth="0.72"
                                                    />
                                                    <g>
                                                        {" "}
                                                        <title>times-circle-line</title>{" "}
                                                        <path
                                                        className="clr-i-outline clr-i-outline-path-1"
                                                        d="M19.61,18l4.86-4.86a1,1,0,0,0-1.41-1.41L18.2,16.54l-4.89-4.89a1,1,0,0,0-1.41,1.41L16.78,18,12,22.72a1,1,0,1,0,1.41,1.41l4.77-4.77,4.74,4.74a1,1,0,0,0,1.41-1.41Z"
                                                        />
                                                        <path
                                                        className="clr-i-outline clr-i-outline-path-2"
                                                        d="M18,34A16,16,0,1,1,34,18,16,16,0,0,1,18,34ZM18,4A14,14,0,1,0,32,18,14,14,0,0,0,18,4Z"
                                                        />{" "}
                                                        <rect x={0} y={0} width={36} height={36} fillOpacity={0} />{" "}
                                                    </g>
                                                </svg>
                                                <h5 className="mt-4" style={{color: '#555'}}>Payment Cancelled!</h5>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                ) : !!success || details?.paymentComplete === true ? (
                                    <div className="d-flex align-items-center">
                                        <Card className="w-100">
                                            <Card.Body className="text-center py-5">
                                                <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                                    <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                                    <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                                                </svg>
                                                <h5 className="mt-4" style={{color: '#555'}}>{success || 'Payment Complete'}!</h5>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                ) : (
                                    <>
                                    <div>
                                        <PayButton 
                                            totalAmount={details?.amount || 0} 
                                            totalLabel="Total Payment"
                                            onSupported={handleSupportPayButton}
                                            onPayment={handlePaymentOnButton} />
                                    </div>
                                    <FormManager onSubmit={handleSubmit}>
                                        {showDivider ? (
                                            <div className="divider">
                                                <hr/>
                                                <p className="divider-text text-muted">Or pay with card</p>
                                            </div>
                                        ) : (
                                            <h5 className="mb-4">Pay with card</h5>
                                        )}
                                        <CardElementForm flat className="mb-3" onReady={handleReady} onChange={handleCardChange}/>
                                        <Alert variant="danger" hidden={!error} dismissible onClose={() => setStates(prev => ({...prev, error: null}))}>
                                            <h6>Payment Failed</h6>
                                            {error}
                                        </Alert>
                                        <div className="d-flex justify-content-center">
                                            <Button type="submit" className="call-to-action-background mb-3" block disabled={readyPayButton || request.loading || submitting} loading={request.loading || submitting}>Pay Now</Button>
                                        </div>
                                    </FormManager>
                                    </>
                                )}
                            </div>
                            <div className="d-block d-sm-none">
                                <p className="text-center text-footer my-3">Powered by <b>Rendr</b></p>
                            </div>
                        </Col>
                    </Row>
                </div>
            ) : (
                <div className="page-not-found">
                    <h1>404</h1>
                    <h3>Page not found</h3>
                </div>
            )}
        </Container>
    )
}

export default Layout
