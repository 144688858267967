export default {
    logo: '',
    brandName: 'Rendr Payment',
    landingPath: '/',
    applicationAreaID: 2,
    services: {
        endpoint: process.env.REACT_APP_DATA_API_URL!,
        gql: process.env.REACT_APP_GRAPH_API_URL!,
        env: process.env.REACT_APP_ENV!
    },
    token: {
        name: 'rendrTK',
        expireday: 1, // 1 day
        domain: window.location.hostname.split('.').slice(1).join('.') || '',
        expireMessage: 'To protect your data, you have been logged out. Please login again to continue.'
    },
    toaster: {
        soundEffectEnable: false,
        pathSoundEffectIn: '/media/audio/mixkit-message-pop-alert.mp3',
        pathSoundEffectOut: '/media/audio/mixkit-long-pop.wav'
    },
    messages: {
        error: process.env.REACT_APP_ENV === 'production' ? 'Something went wrong.' : 'There is an issue with the endpoint.',
        fail: 'There are errors with your entry. Please check and try again.'
    }
}