import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useAuthAPI } from '@whitecobalt/tungsten/esm/common/hooks/useAuthAPI';
import React, { useEffect, useState } from 'react'

interface StripeProviderProps {
    stripeAccountRequired?: boolean;
    stripeAccount?: string;
}

const StripeProvider: React.FunctionComponent<StripeProviderProps> = ({stripeAccountRequired, stripeAccount, children}) => {
    const [promise, setPromise] = useState<any>(null);
    const requestStripekey = useAuthAPI('/api/PaymentCard/stripekey', { method: 'GET' })
    
    useEffect(() => {
        if(stripeAccountRequired === false || (stripeAccountRequired === true && stripeAccount)) {
            requestStripekey.call().then(response => {
                return loadStripe(response.data, stripeAccountRequired ? {
                    stripeAccount,
                } : undefined)
            })
            .then((stripePromise) => {
                setPromise(stripePromise)
            })
            .catch(() => {
                // setPromise(loadStripe('pk_test_6fuu19tqfyAOroKL8UalHf8k'))
            })
        }
    }, [stripeAccountRequired, stripeAccount])

    return (
        <Elements stripe={promise!}>
            {children}
        </Elements>
    )
}

StripeProvider.defaultProps = {
    stripeAccountRequired: false
}

export default StripeProvider
