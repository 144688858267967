import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js';
import Placeholder from '@whitecobalt/tungsten/Placeholder';
import React, { useState } from 'react'
import classNames from 'classnames'
import './index.scss'
import { StripeCardNumberElementChangeEvent } from '@stripe/stripe-js';

interface CardElementFormProps {
    className?: string;
    flat?: boolean;
    onReady?: () => void;
    onChange?: (event: StripeCardNumberElementChangeEvent) => void;
}

const CardElementForm: React.FunctionComponent<CardElementFormProps> = ({
    className, 
    flat, 
    onReady,
    onChange
}) => {
    const [ready, setReady] = useState(false)
    return (
       <div className={classNames("card-element-form", {flat}, className)}>
            <div className="form-group">
                <label>Card Number</label>
                <Placeholder loading={!ready}/>
                <div className={ready ? 'd-block' : 'd-none'}>
                    <CardNumberElement onChange={onChange} options={{showIcon: true}} onReady={() => {
                        setReady(true)
                        if(onReady)
                            onReady()
                    }}/>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="form-group">
                        <label>Expiry Date</label>
                        <Placeholder loading={!ready}/>
                        <div className={ready ? 'd-block' : 'd-none'}>
                            <CardExpiryElement />
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label><i className="fas fa-lock"></i> CVC</label>
                        <Placeholder loading={!ready}/>
                        <div className={ready ? 'd-block' : 'd-none'}>
                            <CardCvcElement />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CardElementForm
