import React, {useState, useEffect} from 'react';
import {PaymentRequestButtonElement, useStripe} from '@stripe/react-stripe-js';

interface PayButtonProps {
    country?: string,
    currency?: string,
    totalAmount: number;
    totalLabel: string;
    requestPayerName?: boolean;
    requestPayerEmail?: boolean;
    onSupported?: (supported: boolean) => void;
    onPayment: (event: any) => void;
}

const PayButton: React.FunctionComponent<PayButtonProps> = ({
    country,
    currency,
    totalAmount,
    totalLabel,
    requestPayerEmail,
    requestPayerName,
    onSupported,
    onPayment
}) => {
    const stripe = useStripe();
    const [paymentRequest, setPaymentRequest] = useState<any>(null);

    useEffect(() => {
        if (stripe && !paymentRequest && totalAmount) {
            const pr = stripe.paymentRequest({
                country: country!,
                currency: currency!,
                total: {
                    label: totalLabel,
                    amount: Number(totalAmount) * 100,
                },
                requestPayerName: requestPayerName,
                requestPayerEmail: requestPayerEmail
            });

            // Check the availability of the Payment Request API.
            pr.canMakePayment().then(result => {

                if(onSupported) {
                    onSupported(!!result)
                }

                if (result) {
                    setPaymentRequest(pr);
                }
            })
        }
    }, [stripe]);

    useEffect(() => {
        if(paymentRequest) {
            paymentRequest.on('paymentmethod', onPayment)
    
            return () => {
                paymentRequest.off('paymentmethod', onPayment);
            }
        }
    }, [paymentRequest, onPayment])

    if (paymentRequest) {
        return <PaymentRequestButtonElement options={{paymentRequest}} />
    }

    // Use a traditional checkout form.
    return null;
}

PayButton.defaultProps = {
    country: 'GB',
    currency: 'gbp',
    requestPayerName: true,
    requestPayerEmail: false
}

export default PayButton

